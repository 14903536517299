.ant-picker {
    z-index: 11600 !important;
    padding: 0.7em !important;
}

.active-row .ant-table-cell-fix-right {
    background-color: transparent !important;
}

.ant-picker-panel-container {
    z-index: 11600 !important;
}

.ant-picker-panel-layout {
    z-index: 11600 !important;
}

.ant-picker-dropdown {
    z-index: 11600 !important;
}

.custom-modal {
    z-index: 10000;
}

.custom-modal .modal-header {
    border: none;
}

.custom-modal .modal-title {
    font-size: 1.1em;
    font-weight: 700;
}

.crosshair {
    top: 0;
    left: 0;
    z-index: 1;
    box-sizing: border-box;
    border: 27em solid rgba(0, 0, 0, 0.3);
    box-shadow: rgba(255, 0, 0, 0.5) 0px 0px 0px 5px inset;
    position: absolute;
    width: 87%;
    padding-bottom: 25em;
    margin-top: -280px;
    margin-left: 180px;
    height: 100%;
}

.tool {
    position: absolute;
    z-index: 999999;
    width: 80vw;
    top: 90%;
    height: 20em;
    background: #f9fafb;
    right: 0;
}

.crosshair-full {
    top: -50%;
    left: 0;
    z-index: 1;
    box-sizing: border-box;
    border: 30em solid rgba(0, 0, 0, 0.3);
    box-shadow: rgba(255, 0, 0, 0.5) 0px 0px 0px 5px inset;
    position: absolute;
    width: 100%;
    padding-bottom: 30em;
    /* margin-top: -320px; */
    height: 100vh;
}

@media all and (display-mode: fullscreen) {

    /* every CSS goes here that you want 
    to apply or alter in the fullscreen mode*/
    .crosshair-full {
        top: -30% !important;
        left: 0;
    }

    body {
        overflow: hidden !important;
    }
}

@media screen and (min-width: 320px) and (max-width: 786px) {
    .crosshair {
        width: 100% !important;
        height: 100%;
    }
}

/*video {*/
/*    overflow: hidden !important;*/
/*    width: 100%;*/
/*    height: 100vh !important;*/
/*    object-fit: cover !important;*/
/*}*/

.info-qr-content {
    padding: 0 30px 30px 30px;
    position: relative;
    line-height: 2;
}

.text-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
}

.text-row .label-text {
    font-weight: 700;
    width: 70%;
}

.text-row .filled-text {
    text-align: right;
    width: 50%;
}

.ant-message-top {
    z-index: 11000 !important;
}

.ant-select-selector {
    z-index: 9999 !important;
}

.ant-select-selection-item {
    z-index: 9999 !important;
}

.ant-select-select-open {
    z-index: 9999 !important;
}

.modal-backdrop.show {
    z-index: 10000 !important;
    opacity: 0.8 !important;
}

.name-tag-modal {
    /*width : 535px*/
}

.MuiTablePagination-selectLabel {
    margin: 0 !important;
}

.MuiTablePagination-displayedRows {
    margin: 0 !important;
}

.hide {
    position: absolute !important;
    top: -9999px !important;
    left: -9999px !important;
}

.grid-profile {
    margin-top: 15px !important;
}

.ant-image-preview-img {
    max-width: 700px !important;
}

.chart-context-content .apexcharts-legend.apx-legend-position-top.apexcharts-align-center {
    justify-content: center;
    display: contents;
}

#react-qrcode-logo {
    height: 180px !important;
    width: 180px !important;
}


@font-face {
    font-family: 'Evogria';
    /* Give your font a name */
    src: url('./assets/Evogria.otf') format('truetype');
    /* Specify the path to your TTF font file */
}

/* Example usage */
.evogria-font {
    font-family: 'Evogria';
    /* Use the font you imported */
}

/* .company-name {
    display: -webkit-box !important; 
    -webkit-line-clamp: 2 !important;
    line-clamp: 2 !important;
    overflow: hidden;
    -webkit-box-orient: vertical !important;
} */

.usertag-roles {
    position: relative;
    /* left: 29%; */
    text-align: center;
    /* bottom: 130px; */
    bottom: 80px;
    font-weight: 700;
    font-size: 3em;
    font-family: 'Evogria';
    word-spacing: 20px;
}